* {
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
  color: rgb(85, 85, 85);
}
#root {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.initial-header {
  background-color: white;
  color: rgb(85, 85, 85);
}
.initial-loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid rgb(205, 90, 110);
  border-bottom: 16px solid rgb(190, 220, 230);
  border-left: 16px solid rgb(235, 230, 215);
  border-right: 16px solid rgb(85, 85, 85);
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  align-self: center;
  justify-self: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

body {
  margin: 0px;
  background-color: white;
}
.treat-as-button,
button,
input[type="button"],
input[type="submit"],
input[type="reset"] {
  background-color: rgb(150, 25, 50);
  color: white;
  border: none;
  padding: 0.5em 2em;
  font-size: medium;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.treat-as-button:hover,
button:hover,
input[type="button"]:hover,
input[type="submit"]:hover,
input[type="reset"]:hover {
  cursor: pointer;
}
.treat-as-button:disabled,
button:disabled,
input[type="button"]:disabled,
input[type="submit"]:disabled,
input[type="reset"]:disabled {
  background-color: rgb(85, 85, 85);
}
.treat-as-button:active,
.click:active,
button:active,
input[type="button"]:active,
input[type="submit"]:active,
input[type="reset"]:active {
  animation: pulse 0.2s;
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.95);
  }
}
.button-image {
  padding-right: 0.5em;
  filter: invert(99%) sepia(99%) saturate(14%) hue-rotate(250deg)
    brightness(104%) contrast(100%);
}
input,
select {
  font-size: medium;
  border: none;
  border-bottom: 1px solid #dcdcdc;
  padding: 0.25em;
}
h1 {
  background-color: rgb(150, 25, 50);
  color: white;
  text-transform: uppercase;
  padding: 0.75em 1em;
  font-size: x-large;
  font-weight: normal;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  width: 600px;
  min-width: 60%;
  max-width: 95%;
  min-height: 100vh;
  margin: auto;
}

.content-area {
  flex-grow: 2;
  margin-bottom: 1em;
}
a,
button.link {
  border-bottom: 1px solid transparent;
}
a:hover,
button.link:hover {
  border-bottom: solid 1px rgb(150, 25, 50);
}

label p {
  font-weight: bold;
}
